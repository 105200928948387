<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <!-- <el-select v-model="select.is_stop" placeholder="状态" clearable style="width:140px" @change="curr=1;getList()">
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
            </el-select> -->
            <el-input placeholder="车牌号/姓名" v-model.trim="searchValue" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:150px;margin-right:auto;" @input="curr=1;getList()"></el-input>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="car_no" label="车牌号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="run_long" label="公里数(km)" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="realname" label="姓名" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="tel" label="手机号" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {
        getList: function() {
            this.http.post('/admin.car/index', {
                curr: this.curr,
                row: this.row,
                search: this.searchValue
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
            })
        },
        // 图片转换
        getImgUrl(item, type) {
            var url
            if (type == 'arr') {
                url = [this.domain + item];
            } else {
                url = this.domain + item;
            }
            return url;
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
